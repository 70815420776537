import React from "react";
import { Navigate, Route, Routes } from "react-router";
import "./App.css";
import { lazy, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
// import "@fontsource/space-grotesk";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import LoadinIcon from "./Layout/loadingIcon";
import LocalStorageProvider from "./components/Provider/LocalStorageProvider";

const PaymentInfo = React.lazy(() => import('./components/PartnerOnboardReady/Payment/PaymentInfo'));
const Payout = React.lazy(() => import('./components/PartnerOnboardReady/Payout/Payout'));
const ViewTransaction = React.lazy(() => import('./components/PartnerOnboardReady/ViewTransaction/ViewTransaction'));
const UserInformation = React.lazy(()=>import( './components/PartnerOnboardReady/UserInformation/UserInformation'));
const Profile = React.lazy(() =>import('./components/Account/Profile/Profile'));
const Layout= React.lazy(() =>import('./Layout/Layout'));
const AdminTab = React.lazy(() => import('./components/AdminTabcontent/AdminTab'));
const InstrailsFees = React.lazy(() => import('./components/AdminTabcontent/InstrailsFees'));
const  PartnerTable = React.lazy(() => import('./components/Partner/PartnerTable/PartnerTable'));
const UserManagement = React.lazy(() => import('./components/Users/UserManagement'));
const Details = React.lazy(() => import ('./Layout/Details'));
const Login = React.lazy(() => import('./components/Account/Login/Login'));
const Payment = React.lazy(() => import('./Layout/Payment'));
const Paymentsettingread = React.lazy(()=> import('./components/Partner/PaymentReadonly/Paymentsettingread'));
const Dashboard = React.lazy(()=>import('./Layout/Dashboard'));


export default function App() {
  return (

    <LocalStorageProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Suspense ><Login /></Suspense>} />
        <Route path="login" element={<Navigate to="/" />} />

        <Route path="/admin" element={<Layout />}>
          <Route index element={<Details />} />
          <Route path=":partnerdetails/:partnerid/:type" element={<Suspense ><Details /></Suspense>} />

          <Route path=":partnerdetails/payment/:partnerid/:type" element={<Suspense ><Payment /></Suspense>} />

          <Route path="/admin/usermanagement" element={<Suspense ><UserManagement /></Suspense>} />
          <Route path=":dashboard" element={<Suspense ><Dashboard /></Suspense>} />
          <Route path="/admin/partner" element={<Suspense ><PartnerTable /></Suspense>} />
          <Route path=":partner/admin/:partnerid" element={<Suspense ><AdminTab /></Suspense>} />
          <Route path=":partner/admin/fees" element={<InstrailsFees />} />
          <Route path=":partner/admin/userinfo" element={<Suspense ><UserInformation /></Suspense>} />
        
        </Route>

        <Route path="*" element={<Suspense ><PageNotFound /></Suspense>} />
        {/* <Route path="/Paymentsettingread" element={<Paymentsettingread />} /> */}
        <Route path="/paymentinfo" element={<Suspense ><PaymentInfo /></Suspense>} />
        <Route path="/payout" element={<Suspense ><Payout /></Suspense>} />
        <Route path="/viewtransaction" element={<Suspense ><ViewTransaction /></Suspense>} />
        <Route path="/profile" element={<Suspense><Profile /></Suspense>} />
       
      </Routes>
   
    </BrowserRouter>
    </LocalStorageProvider>

  );
}

function PageNotFound() {
  return (
    <div>
      <h2>404 Page not found</h2>
    </div>
  );
}
